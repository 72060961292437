import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './components/app.component';
import { RecipeComponent } from './components/recipe.component';
import { EnglishComponent } from './components/english.component';
import { JsonService } from './services/json.service';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';

@NgModule({
    declarations: [
        AppComponent,
        RecipeComponent,
        EnglishComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        RouterModule.forRoot(ROUTES)
    ],
    providers: [
        { provide: JsonService, useClass: JsonService },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
