export class Assaisonnement {

    constructor(public id: string,
                public name: string,
                public sickness: number,
                public prefix: string,
                public otherNames: Array<string>) {
    }

    getPrefix() {
        if (this.prefix === null) {
            return '';
        }
        console.log(typeof this.prefix);
        return (typeof this.prefix !== 'undefined') ? this.prefix : 'et';
    }
}
