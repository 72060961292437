import { Component } from '@angular/core';
import { JsonService } from '../services/json.service';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Recipe} from '../models/recipe.model';
import {Meal} from '../models/meal.model';
import {Ingredient} from '../models/ingredient.model';
import {Assaisonnement} from '../models/assaissonnement.model';

@Component({
    selector: 'ns-recipes',
    templateUrl: '../templates/recipe.component.html',
    // styleUrls: ['./app.component.css']
})
export class RecipeComponent {

    mealsList: Array<Meal>;
    ingredientsList: Array<Ingredient>;
    assaisonnementsList: Array<Assaisonnement>;
    formRecipe: FormGroup;
    recipe: Recipe;
    fb: FormBuilder;
    dishonored: boolean;
    mealForgotten: boolean;
    quote: string;

    constructor (private JsonService: JsonService, fb: FormBuilder) {

    JsonService.getMealsList().subscribe(response => {
        // console.log(typeof response);
            this.mealsList = response;
        });

        JsonService.getIngredientsList().subscribe(response => {
            this.ingredientsList = response;
        });

        JsonService.getAssaisonnementList().subscribe(response => {
            this.assaisonnementsList = response;
        });

        JsonService.getQuotesList().subscribe(response => {
            this.quote = response[this.getRandomInt(response.length - 1)];
        });

        this.formRecipe = fb.group({
            meal: fb.control('', [Validators.required]),
            ingredients: fb.control('', [Validators.required]),
            assaisonnements: fb.control(''),
            dishonored: fb.control('')
        });
}

    registerRecipe() {
        if (this.formRecipe.value.meal === '') {
            this.mealForgotten = true;
            return;
        }

        this.dishonored = this.formRecipe.value.dishonored;
        this.recipe = new Recipe(this.formRecipe.value.meal, this.formRecipe.value.ingredients, this.formRecipe.value.assaisonnements);
        this.mealForgotten = false;
        this.generateNewRecipeLabel();
    }

    generateNewRecipeLabel() {
        this.recipe.sickness = (typeof this.recipe.meal.sickness !== 'undefined') ? this.recipe.meal.sickness : 0;
        const randomMealName = this.recipe.meal.otherNames[this.getRandomInt(this.recipe.meal.otherNames.length - 1)];
        const ingredientsName = this.generateIngredientsName(this.recipe.ingredients);
        const assaisonnementsName = this.generateAssaisonnementsName(this.recipe.assaisonnements);
        const dishonoredLabel = (this.dishonored) ? 'revisitée par le chef' : '';
        this.recipe.label = `${randomMealName} ${ingredientsName} ${assaisonnementsName} ${dishonoredLabel}`;
    }

    generateIngredientsName(ingredients: Array<Ingredient>) {
        const name = [];
        ingredients.forEach( (ingredient) => {
            name.push(`${ingredient.getPrefix()} ${ingredient.otherNames[this.getRandomInt(ingredient.otherNames.length - 1)]}`);
            this.recipe.sickness += (typeof ingredient.sickness !== 'undefined') ? ingredient.sickness : 0;
        });
        return name.join(' ');
    }

    generateAssaisonnementsName(assaisonnements: Array<Assaisonnement>) {
        const name = [];
        assaisonnements.forEach( (assaisonnement) => {
            name.push(`${assaisonnement.getPrefix()} ${assaisonnement.otherNames[this.getRandomInt(assaisonnement.otherNames.length - 1)]}`);
            this.recipe.sickness += (typeof assaisonnement.sickness !== 'undefined') ? assaisonnement.sickness : 0;
        });
        return name.join(' ');
    }

    getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    getSicknessCssClass(sickness) {
        if (sickness <= 20) {
            return 'alert-success';
        } else if (sickness > 50 && sickness <= 75) {
            return 'alert-warning';
        } else {
            return 'alert-danger';
        }
    }

    getSicknessExplanation(sickness) {
        if (sickness <= 20) {
            return 'Aucun malade ni mort en vue, bien joué chef';
        } else if (sickness <= 50) {
            return 'Quelques malades sûrement, mais pas assez pour faire le lien avec votre infâme cuisine';
        } else if (sickness > 50 && sickness <= 75) {
            return 'Beaucoup de gens malades ! Pensez à accuser un collègue';
        } else {
            return 'Des malades et des morts sont à prévoir, prenez un bon avocat et évitez d\'arriver bourré à l\'audience comme la dernière fois';
        }
    }
}
