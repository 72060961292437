import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Meal} from '../models/meal.model';
import {Ingredient} from '../models/ingredient.model';
import { filter, map } from 'rxjs/operators';
import {Assaisonnement} from '../models/assaissonnement.model';
@Injectable({
    providedIn: 'root'
})
export class JsonService {
    constructor(private http: HttpClient) {
    }

    sortByName(a, b) {
        return a.name.localeCompare(b.name);
    }

    getMealsList() {
        return this.http.get('assets/json/meals.json').pipe(
            map(response => {
                const res = this.parseJson(response);

                return res.sort((a, b) => this.sortByName(a, b)).map(item => {
                    return new Meal(
                        item.id,
                        item.name,
                        item.sickness,
                        item.otherNames
                    );
                });
        })
    );
    }

    getIngredientsList() {
    return this.http.get('assets/json/ingredients.json').pipe(
        map(response => {
            let res = [];
            try {
                res = this.parseJson(response);
            } catch (e) {
                throw Error('Unable to parse ingredients json');
            }

            return res.sort((a, b) => this.sortByName(a, b)).map(item => {
                return new Ingredient(
                    item.id,
                    item.name,
                    item.prefix,
                    item.sickness,
                    item.otherNames
                );
            });
        })
    );
}

    getAssaisonnementList() {
        return this.http.get('assets/json/assaisonnements.json').pipe(
            map(response => {
                const res = this.parseJson(response);
                return res.sort((a, b) => this.sortByName(a, b)).map(item => {
                    return new Assaisonnement(
                        item.id,
                        item.name,
                        item.sickness,
                        item.prefix,
                        item.otherNames
                    );
                });
            })
        );
    }

    getQuotesList() {
        return this.http.get('assets/json/quotes.json').pipe(
            map(response => {
                const res = this.parseJson(response);
                return res.map(item => {
                    return item;
                });
            })
        );
    }


    parseJson(jsonData) {
        const array = [];
        for (let i = 0; i < jsonData.length; i++) {
            array.push(jsonData[i]);
        }
        return array;
    }
}