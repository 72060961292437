import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ns-app',
  templateUrl: '../templates/app.component.html'
})
export class AppComponent {
  constructor(title: Title) {
    title.setTitle('Recipe + 4000 : générateur de nom pompeux');
  }

  title = 'Recipe + 4000 : générateur de nom pompeux';
}
