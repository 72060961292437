import {Meal} from './meal.model';
import {Ingredient} from './ingredient.model';
import {Assaisonnement} from './assaissonnement.model';

export class Recipe {
    public label: string;
    public sickness: number;

    constructor(public meal: Meal,
                public ingredients: Array<Ingredient>,
                public assaisonnements: Array<Assaisonnement>) {
        this.sickness = 0;
    }
}